import React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Side ikke fundet" />
    <h1>Side findes ikke</h1>
    <p>Du har desværre ramt en side der ikke findes</p>
  </Layout>
);

export default NotFoundPage;
